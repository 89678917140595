import axios from "axios";

export const FETCH_PLAYERS = "FETCH_PLAYERS";
export const FETCH_ACTIONS = "FETCH_ACTIONS";
export const FETCH_HISTORY = "FETCH_HISTORY";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_TEAMS = "FETCH_TEAMS";
export const DO_ACTION = "DO_ACTION";

export const fetchPlayers = () => dispatch =>
  axios.get("/api/players").then(response =>
    dispatch({
      type: FETCH_PLAYERS,
      payload: response.data
    })
  );

export const fetchActions = () => dispatch =>
  axios.get("/api/actions").then(response =>
    dispatch({
      type: FETCH_ACTIONS,
      payload: response.data
    })
  );

export const fetchHistory = () => dispatch =>
  axios.get("/api/history").then(response =>
    dispatch({
      type: FETCH_HISTORY,
      payload: response.data
    })
  );

export const fetchProducts = () => dispatch =>
  axios.get("/api/products").then(response =>
    dispatch({
      type: FETCH_PRODUCTS,
      payload: response.data
    })
  );

export const fetchTeams = () => dispatch =>
  axios.get("/api/teams").then(response =>
    dispatch({
      type: FETCH_TEAMS,
      payload: response.data
    })
  );

export const doAction = params => dispatch =>
  axios.put("/api/do", params).then(response => {
    dispatch(fetchPlayers());
    dispatch(fetchHistory());

    return dispatch({
      type: DO_ACTION
    });
  });
