import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  fetchPlayers,
  fetchActions,
  fetchHistory,
  fetchProducts,
  fetchTeams,
  doAction,
} from "store/global/actions";
import Select from "react-select";
import moment from "moment";
import "./Actions.css";
import historyTitle from "./assets/history-of-actions.png";
import hand from "./assets/hand.png";
import boom from "./assets/boom.png";
import crash from "./assets/crash.png";
import pow from "./assets/pow.png";
import wow from "./assets/wow.png";
import inProgress from "./assets/in-progress.png";
import parse from "html-react-parser";
import styled, { keyframes } from "styled-components";
import { tada, bounceIn, bounceInUp, bounceInRight } from "react-animations";

const Tada = styled.div`
  animation: 1s ${keyframes`${tada}`};
`;
const BounceInUp = styled.div`
  animation: 0.5s ${keyframes`${bounceInUp}`};
`;
const BounceIn = styled.div`
  animation: 1s alternate ${keyframes`${bounceIn}`};
`;
const BounceInRight = styled.div`
  animation: 0.8s ${keyframes`${bounceInRight}`};
`;

class Actions extends Component {
  constructor(props) {
    super(props);
    this.historyRef = React.createRef();
  }

  componentDidMount() {
    const inProgressImg = new Image();
    inProgressImg.src = inProgress;

    const {
      fetchPlayers,
      fetchActions,
      fetchHistory,
      fetchProducts,
      fetchTeams,
    } = this.props;

    fetchPlayers();
    fetchActions();
    fetchHistory();
    fetchProducts();
    fetchTeams();
  }

  state = {
    error: "",
    actionInProgress: false,
    playerFrom: "",
    product: "",
    playerTo: "",
    domain: "",
  };

  _onChange = (field, value) => {
    let newState = {};

    switch (field) {
      case "playerFrom":
        newState = {
          playerFrom: value,
          product: "",
          playerTo: "",
        };
        break;

      case "product":
        newState = {
          product: value,
          playerTo: "",
        };
        break;

      case "playerTo":
        newState = {
          playerTo: value,
        };
        break;

      case "domain":
        newState = {
          domain: value,
        };
        break;
      default:
        break;
    }

    this.setState(newState);
  };

  _checkIsValidDomain = (domain) => {
    var re = new RegExp(
      /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/
    );
    return domain.match(re);
  };

  _showError = (error) => {
    this.setState({ error });
    setTimeout(() => {
      this.setState({
        error: "",
      });
    }, 3000);
  };

  _doAction = () => {
    const { fetchPlayers } = this.props;
    const { playerFrom, product, playerTo, domain } = this.state;

    const params = {
      playerFrom: playerFrom.id,
      product: product.id,
      domain: domain,
    };

    if (playerTo.name !== "Heroes" && playerTo.name !== "Villains") {
      params.playerTo = playerTo.id;
    }

    if (this._checkIsValidDomain(domain)) {
      this.setState({
        actionInProgress: true,
      });

      this.props
        .doAction(params)
        .then(() => {
          this.setState({
            actionInProgress: false,
            playerFrom: "",
            product: "",
            playerTo: "",
            domain: "",
            error: "",
          });
          return fetchPlayers();
        })
        .catch(({ response }) => {
          this._showError(response.data);
          this.setState({ actionInProgress: false });
        });
    } else {
      this._showError("Domain is not valid");
    }
  };

  _getRecordText = (record) => {
    return (
      <div className="record" key={record.id}>
        <div className="date">{moment(record.created_at).format("lll")}:</div>
        <div className="message">{parse(record.message)}</div>
      </div>
    );
  };

  _getOptionText = (option) => {
    return option.team
      ? `[${option.team.name}] ${option.name}`
      : `[${option.name}]`;
  };

  render() {
    const { players, actionHistory, products, teams, user } = this.props;
    const {
      playerFrom,
      product,
      playerTo,
      actionInProgress,
      domain,
      error,
    } = this.state;

    if (!user) {
      return null;
    }

    let currentTeam,
      opositeTeam,
      playersTo = [],
      allowedProducts = [...products];

    const playersFrom = players.filter((player) => player.health > 0);

    if (playerFrom) {
      currentTeam = teams.find((team) => team.id === playerFrom.team.id);
      opositeTeam = teams.find((team) => team.id !== playerFrom.team.id);
      allowedProducts = products.filter(
        (product) => product.isSecret === playerFrom.isSecret
      );
    }

    if (product) {
      if (product.isSecret) {
        playersTo = players.filter(
          (player) =>
            player.team.id === currentTeam.id &&
            player.isSecret === playerFrom.isSecret
        );
      } else if (product.canRevive) {
        playersTo = players.filter(
          (player) => player.team.id === currentTeam.id && player.health === 0
        );
      } else {
        switch (product.action.name) {
          case "Add points":
            playersTo = players.filter(
              (player) => player.team.id === currentTeam.id
            );

            if (!product.myself) {
              playersTo = playersTo.filter(
                (player) => player.id !== playerFrom.id
              );
            }

            if (product.wholeTeam) {
              playersTo = [currentTeam];
            }

            break;

          case "Remove points":
            playersTo = players.filter(
              (player) =>
                player.team.id === opositeTeam.id && player.health !== 0
            );

            if (product.wholeTeam) {
              playersTo = [opositeTeam];
            }

            break;
          default:
            playersTo = players;
        }
      }
    }

    return (
      <div className="actions">
        <section className="new-action">
          <BounceInUp>
            <div className="make-new-action">
              <div className="row">
                <div className="column">
                  <label>From:</label>
                  <Select
                    placeholder="Select player"
                    classNamePrefix="select-box"
                    value={playerFrom}
                    options={playersFrom}
                    getOptionLabel={this._getOptionText}
                    getOptionValue={(player) => player}
                    onChange={(player) => {
                      this._onChange("playerFrom", player);
                    }}
                  />
                </div>

                <div className="column">
                  <label>Action:</label>
                  <Select
                    placeholder="Select action"
                    isDisabled={!playerFrom}
                    classNamePrefix="select-box"
                    value={product}
                    options={allowedProducts}
                    getOptionLabel={(product) => product.name}
                    getOptionValue={(product) => product}
                    onChange={(product) => {
                      this._onChange("product", product);
                    }}
                  />
                </div>

                <div className="column">
                  <label>To:</label>
                  <Select
                    placeholder="Select player"
                    classNamePrefix="select-box"
                    isDisabled={!product || !playerFrom}
                    value={playerTo}
                    options={playersTo}
                    getOptionLabel={this._getOptionText}
                    getOptionValue={(player) => player}
                    onChange={(player) => {
                      this._onChange("playerTo", player);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="column">
                  <label>Domain:</label>
                  <input
                    type="text"
                    value={domain}
                    className="textfield"
                    placeholder="Specify domain"
                    onChange={({ target: { value } }) => {
                      this._onChange("domain", value);
                    }}
                  />
                  {actionInProgress && (
                    <div className="in-progress">
                      <BounceIn>
                        <img src={inProgress} alt="" />
                      </BounceIn>
                    </div>
                  )}

                  <button
                    disabled={!playerFrom || !product || !playerTo}
                    className="go-button"
                    onClick={(e) => this._doAction(e)}
                  ></button>
                </div>
              </div>

              {error.length > 0 && <div className="error">{error}</div>}

              <div className="text-row">
                <div className="label">How will your story end?...</div>
                <div className="button">
                  <Link to={"/teams"}>
                    <button className="check-your-progress"></button>
                  </Link>
                </div>
              </div>
            </div>
          </BounceInUp>

          <div className="design-image hand">
            <BounceInRight>
              <img src={hand} alt="" />
            </BounceInRight>
          </div>
        </section>

        <section className="history" ref={this.historyRef}>
          <div className="design-image history-title">
            <Tada>
              <img src={historyTitle} alt="History of actions" />
            </Tada>
          </div>
          <div className="design-image crash">
            <img src={crash} alt="Crash" />
          </div>
          <div className="design-image pow">
            <Tada>
              <img src={pow} alt="Pow" />
            </Tada>
          </div>
          <div className="design-image wow">
            <Tada>
              <img src={wow} alt="Wow" />
            </Tada>
          </div>
          <div className="design-image boom">
            <Tada>
              <img src={boom} alt="Boom" />
            </Tada>
          </div>

          <div className="history-table">
            <div className="inner">
              {actionHistory && actionHistory.length > 0 ? (
                actionHistory
                  .sort((a, b) =>
                    moment(b.created_at).diff(moment(a.created_at))
                  )
                  .map((record) => this._getRecordText(record))
              ) : (
                <div>No actions</div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  players: state.global.players,
  actions: state.global.actions,
  actionHistory: state.global.history,
  products: state.global.products,
  teams: state.global.teams,
});

const mapDispatchToProps = {
  fetchPlayers,
  fetchActions,
  fetchHistory,
  fetchProducts,
  fetchTeams,
  doAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Actions)
);
