import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { fetchPlayers } from 'store/global/actions';
import Player from 'components/Player';

import './Teams.css';
import logo from './assets/logo.png';
import makeYourMove from './assets/make-your-move.png';

import styled, { keyframes } from 'styled-components';
import { bounceInUp, bounceInDown, pulse, fadeIn } from 'react-animations';

const BounceInUp = styled.div`
  animation: 1s ${keyframes`${bounceInUp}`};
`;
const BounceInDown = styled.div`
  animation: 1s ${keyframes`${bounceInDown}`};
`;
const Pulse = styled.div`
  animation: 1s ${keyframes`${pulse}`} infinite;
`;
const FadeIn = styled.div`
  animation: 1s ${keyframes`${fadeIn}`};
`;

class Teams extends Component {
  componentDidMount() {
    this.props.fetchPlayers();
  }

  render() {
    const { players, user } = this.props;

    const heroes = players.filter((player) => player.team.name === 'Heroes');
    const villains = players.filter(
      (player) => player.team.name === 'Villains'
    );

    if (!user) {
      return null;
    }

    return (
      <div className='teams'>
        <div className='logo'>
          <BounceInDown>
            <img src={logo} alt='Hostopia. A deluxe company' />
          </BounceInDown>
        </div>

        <div className='make-your-move'>
          <Link to='/actions'>
            <BounceInUp>
              <Pulse>
                <img src={makeYourMove} alt='Make your move' />
              </Pulse>
            </BounceInUp>
          </Link>
        </div>

        {heroes.length > 0 && (
          <div className='players heroes'>
            {heroes.map((player) => (
              <Player key={player.id} player={player}></Player>
            ))}
          </div>
        )}

        {villains.length > 0 && (
          <div className='players villains'>
            {villains.map((player) => (
              <Player key={player.id} player={player}></Player>
            ))}
          </div>
        )}

        <FadeIn>
          <div className='total heroes'>
            {heroes.length > 0 && (
              <div className='score'>
                Team total:
                <span>
                  {heroes.reduce((acc, player) => acc + player.health, 0)}
                </span>
              </div>
            )}
          </div>
        </FadeIn>

        <FadeIn>
          <div className='total villains'>
            {villains.length > 0 && (
              <div className='score'>
                Team total:
                <span>
                  {villains.reduce((acc, player) => acc + player.health, 0)}
                </span>
              </div>
            )}
          </div>
        </FadeIn>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  players: state.global.players
});

const mapDispatchToProps = {
  fetchPlayers
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Teams));
