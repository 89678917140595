import axios from 'axios';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const WHO_AM_I = 'WHO_AM_I';
export const LOGOUT = 'LOGOUT';

export const loginRequest = () => ({
  type: LOGIN_REQUEST
});

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload
});

export const loginFailure = (payload) => ({
  type: LOGIN_FAILURE,
  payload
});

export const whoAmI = () => (dispatch) => {
  return axios.get('/api/player/whoami').then((response) => {
    dispatch({
      type: WHO_AM_I,
      payload: response.data
    });
    return response;
  });
};

export const login = (username, password) => (dispatch) => {
  dispatch(loginRequest());
  return axios
    .post('/api/player/login', { username, password })
    .then((response) => {
      if (response.status === 200) {
        dispatch(loginSuccess());
      } else {
        throw new Error(response);
      }
      return dispatch(whoAmI());
    })
    .catch(() => {
      dispatch(loginFailure());
    });
};

export const logout = (username, password) => (dispatch) => {
  return axios.get('/logout').then(() => {
    dispatch({
      type: LOGOUT
    });
  });
};
