import React, { PureComponent } from 'react';
import './Player.css';
import * as avatars from './assets/avatars';

import styled, { keyframes } from 'styled-components';
import { flipInY } from 'react-animations';

const FlipInY = styled.div`
  animation: 0.7s ${keyframes`${flipInY}`};
`;

function perc2color(points) {
  let color = '#000';
  if (points > 70) {
    color = 'green';
  } else if (points <= 70 && points > 30) {
    color = 'darkorange';
  } else if (points <= 30 && points > 0) {
    color = 'red';
  } else if (points <= 0) {
    color = 'gray';
  }

  return color;
}

class Player extends PureComponent {
  _getScore = (health) => {
    const color = {
      color: perc2color(health)
    };

    return <span style={color}>{health}</span>;
  };

  render() {
    const { player } = this.props;

    return (
      <div className='player'>
        <FlipInY>
          <div className={`avatar ${!player.health ? 'grayout' : ''}`}>
            <img src={avatars['Player_' + player.id]} alt={player.name} />
          </div>
          <div className='player-score'>{this._getScore(player.health)}</div>
        </FlipInY>
      </div>
    );
  }
}

export default Player;
