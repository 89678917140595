import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { global } from './global/reducers';
import { authentication } from './authentication/reducers';

const rootReducer = combineReducers({
  global,
  authentication
});

/* eslint-disable no-underscore-dangle */

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer /* preloadedState, */,
  composeEnhancer(applyMiddleware(thunkMiddleware))
);
/* eslint-enable */

export default store;
