import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Teams from 'components/Teams';
import Actions from 'components/Actions';
import Login from 'components/Login';
import './App.css';
import { whoAmI } from 'store/authentication/actions';

class App extends Component {
  componentDidMount() {
    const { whoAmI, history } = this.props;

    whoAmI().catch(() => {
      history.push('/login');
    });
  }
  render() {
    return (
      <>
        <Switch>
          <Route exact path={'/'}>
            <Redirect to={'/login'} />
          </Route>
          <Route exact path={'/login'} component={Login} />
          <Route exact path={'/teams'} component={Teams} />
          <Route exact path={'/actions'} component={Actions} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user
});

const mapDispatchToProps = {
  whoAmI
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
