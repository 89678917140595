import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  WHO_AM_I,
  LOGOUT
} from './actions';

const initialState = {
  user: null,
  isPending: false,
  isAuthenticated: true
};

export const authentication = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isPending: true
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isPending: false,
        isAuthenticated: true
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isPending: false
      };

    case WHO_AM_I:
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      };

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false
      };
    default:
      return state;
  }
};
