import {
  FETCH_PLAYERS,
  FETCH_ACTIONS,
  FETCH_HISTORY,
  FETCH_PRODUCTS,
  FETCH_TEAMS
} from './actions';

const initialState = {
  players: [],
  actions: [],
  history: [],
  products: [],
  teams: []
};

export const global = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PLAYERS:
      return {
        ...state,
        players: payload
      };

    case FETCH_ACTIONS:
      return {
        ...state,
        actions: payload
      };

    case FETCH_HISTORY:
      return {
        ...state,
        history: payload
      };

    case FETCH_PRODUCTS:
      return {
        ...state,
        products: payload
      };

    case FETCH_TEAMS:
      return {
        ...state,
        teams: payload
      };

    default:
      return state;
  }
};
