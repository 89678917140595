import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login } from 'store/authentication/actions';

import './Login.css';

import styled, { keyframes } from 'styled-components';
import { pulse } from 'react-animations';

import loginImg from './assets/login.png';

const Pulse = styled.div`
  animation: 1s ${keyframes`${pulse}`} infinite;
`;

class Login extends Component {
  state = {
    error: '',
    username: '',
    password: ''
  };

  _handleSubmit = (e) => {
    e.preventDefault(e);
    const { login, history } = this.props;
    const { username, password } = this.state;

    login(username, password)
      .then((response) => {
        if (response.status === 200) {
          history.push('/teams');
        } else {
          throw new Error(response);
        }
      })
      .catch(() => {
        this.setState({ error: 'Invalid credentials.' });
      });

    return false;
  };

  render() {
    return (
      <div className='login'>
        <div className='loginForm'>
          {this.state.error && (
            <div className='error'>{this.state.error} Please try again.</div>
          )}
          <form
            autoComplete='off'
            onSubmit={(e) => {
              this._handleSubmit(e);
              return false;
            }}>
            <input
              disabled={this.props.isPending}
              type='text'
              value={this.state.username}
              placeholder='USERNAME'
              className='credentials'
              onChange={({ target }) =>
                this.setState({ username: target.value })
              }
            />
            <input
              disabled={this.props.isPending}
              type='password'
              value={this.state.password}
              placeholder='PASSWORD'
              className='credentials'
              onChange={({ target }) =>
                this.setState({ password: target.value })
              }
            />
            <Pulse>
              <button
                disabled={this.props.isPending}
                type='submit'
                className='submit'>
                <img src={loginImg} alt='Login' />
              </button>
            </Pulse>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isPending: state.authentication.isPending
});

const mapDispatchToProps = {
  login
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
